var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{staticClass:"custom-form",attrs:{"label-position":_vm.labelPosition,"label-width":"100px","model":_vm.orgdetails}},[_c('div',{staticClass:"basic_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("personalInfoHeader")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('companyNepaliName')),
            'is-required': _vm.checkIfRequired(_vm.$t('companyNepaliName'))
          },attrs:{"label":_vm.$t('producerNameUnicode')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('companyNepaliName')},model:{value:(_vm.user.nepname),callback:function ($$v) {_vm.$set(_vm.user, "nepname", $$v)},expression:"user.nepname"}}),(_vm.vErrors.has(_vm.$t('companyNepaliName')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("companyNepaliName")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('companyName')),
            'is-required': _vm.checkIfRequired(_vm.$t('companyName'))
          },attrs:{"label":_vm.$t('producerName')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('companyName')},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),(_vm.vErrors.has(_vm.$t('companyName')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("companyName")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('producerType')),
            'is-required': _vm.checkIfRequired(_vm.$t('producerType'))
          },attrs:{"label":_vm.$t('producerType')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('producerType'),"filterable":""},model:{value:(_vm.user.ssc_type),callback:function ($$v) {_vm.$set(_vm.user, "ssc_type", $$v)},expression:"user.ssc_type"}},_vm._l((_vm.producerType),function(type){return _c('el-option',{key:type.id,attrs:{"value":type.value,"label":type.text}})}),1),(_vm.vErrors.has(_vm.$t('producerType')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("producerType")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('companyEmail')),
            'is-required': _vm.checkIfRequired(_vm.$t('companyEmail'))
          },attrs:{"label":_vm.$t('producerEmail')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email|checkUserExists'),expression:"'required|email|checkUserExists'"}],attrs:{"name":_vm.$t('companyEmail')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.vErrors.has(_vm.$t('companyEmail')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("companyEmail")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('contactPerson')),
            'is-required': _vm.checkIfRequired(_vm.$t('contactPerson'))
          },attrs:{"label":_vm.$t('contactPerson')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('contactPerson')},model:{value:(_vm.user.contact_person),callback:function ($$v) {_vm.$set(_vm.user, "contact_person", $$v)},expression:"user.contact_person"}}),(_vm.vErrors.has(_vm.$t('contactPerson')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("contactPerson")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('contactNumber')),
            'is-required': _vm.checkIfRequired(_vm.$t('contactNumber'))
          },attrs:{"label":_vm.$t('contactNumber')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"name":_vm.$t('contactNumber'),"maxlength":"10"},model:{value:(_vm.user.contact_number),callback:function ($$v) {_vm.$set(_vm.user, "contact_number", $$v)},expression:"user.contact_number"}}),(_vm.vErrors.has(_vm.$t('contactNumber')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("contactNumber")))+" ")]):_vm._e()],1)],1)])]),_c('div',{staticClass:"address_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("addressInfo")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('province')),
            'is-required': _vm.checkIfRequired(_vm.$t('province'))
          },attrs:{"label":_vm.$t('province')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('province'),"filterable":""},model:{value:(_vm.user.provinceid),callback:function ($$v) {_vm.$set(_vm.user, "provinceid", $$v)},expression:"user.provinceid"}},_vm._l((_vm.provinces),function(province){return _c('el-option',{key:province.id,attrs:{"value":province.id,"label":_vm.$i18n.locale == 'ne' && province.nepname ? province.nepname : province.name}})}),1),(_vm.vErrors.has(_vm.$t('province')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("province")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('district')),
            'is-required': _vm.checkIfRequired(_vm.$t('district'))
          },attrs:{"label":_vm.$t('district')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('district'),"filterable":""},model:{value:(_vm.user.districtid),callback:function ($$v) {_vm.$set(_vm.user, "districtid", $$v)},expression:"user.districtid"}},_vm._l((_vm.districts),function(district){return _c('el-option',{key:district.id,attrs:{"value":district.id,"label":_vm.$i18n.locale == 'ne' && district.nepname ? district.nepname : district.name}})}),1),(_vm.vErrors.has(_vm.$t('district')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("district")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('palika')),
            'is-required': _vm.checkIfRequired(_vm.$t('palika'))
          },attrs:{"label":_vm.$t('palika')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('palika'),"filterable":""},model:{value:(_vm.user.palikaid),callback:function ($$v) {_vm.$set(_vm.user, "palikaid", $$v)},expression:"user.palikaid"}},_vm._l((_vm.palikas),function(palika){return _c('el-option',{key:palika.id,attrs:{"value":palika.id,"label":((_vm.$i18n.locale == 'ne' && palika.nepname ? palika.nepname : palika.name) + " " + (palika.palika_type_name
                    ? _vm.$i18n.locale == 'ne' && palika.palika_type_nepname
                      ? palika.palika_type_nepname
                      : palika.palika_type_name
                    : ''))}})}),1),(_vm.vErrors.has(_vm.$t('palika')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("palika")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-2 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('ward')),
            'is-required': _vm.checkIfRequired(_vm.$t('ward'))
          },attrs:{"label":_vm.$t('ward')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min_value:1'),expression:"'required|min_value:1'"}],attrs:{"type":"number","name":_vm.$t('ward')},model:{value:(_vm.user.ward),callback:function ($$v) {_vm.$set(_vm.user, "ward", $$v)},expression:"user.ward"}}),(_vm.vErrors.has(_vm.$t('ward')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("ward")))+" ")]):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }