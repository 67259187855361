
































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand, MyAllocation } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  UserStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { error } from "highcharts";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";

@Component({
  components: { UserDetailDocument },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("demandList")}`,
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  fiscalYearId: number | null = null;
  searchCode: string | null = null;
  cropvariety = {} as CropVariety;
  pageNum: number = 1;
  loading: boolean = false;
  language: string = "en";
  dialogFormVisible: boolean = false;
  form: any = "";
  myAllocation: MyAllocation = {} as MyAllocation;
  salesBill: File[] = [] as File[];
  salesBillId: number = 0;
  cropId: number | null = null;
  cropVarietyId: number | null = null;

  get dataList() {
    console.log("dl->", DemandSupplyStore.MyAllocation);
    return DemandSupplyStore.MyAllocation;
  }

  get FiscalYearList() {
    return MetaStore.FiscalYear;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  async uploadDocument(id: number) {
    console.log(id);
  }

  get user() {
    return UserStore.User;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
      return crops;
    }
    return [];
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  roleChecker(roles: String[]) {
    for (let role of roles) {
      if (this.user.role === role) {
        return true;
      }
    }
    return false;
  }

  toggleDialog(allocation: MyAllocation) {
    this.myAllocation = allocation;
    if (allocation.id) {
      this.salesBillId = allocation.id;
    }
    this.dialogFormVisible = true;
  }

  async patchSalesBillValidity(validity: boolean) {
    // let salesBillForm = new FormData();
    let error = false;
    // for (let file of this.salesBill) {
    //   salesBillForm.append("document_submitted", String(validity));
    // }

    await DemandSupplyStore.patchMyAllocationValidity({
      id: this.salesBillId,
      validity: { document_submitted: validity }
    }).catch(err => {
      console.log(error);
      error = true;
    });
    this.dialogFormVisible = false;

    if (error == false) {
      if (validity) {
        this.$notify.success("Document was marked as valid");
      } else {
        this.$notify.success("Document was marked as invalid");
      }
    } else {
      this.$notify.error("There was an error processing the request.");
    }
    await DemandSupplyStore.getVerifyAllocation();
  }

  handleAfterUploadSeedSaleBill(file: any, fileList: any[]) {
    this.salesBill = [];
    this.salesBill.push(file.raw);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  thousandSeparator(amount: number | string) {
    if (amount == null) {
      return null;
    }
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  async search() {
    let query: any = { page: 1 };
    this.pageNum = 1;

    query = this.routerReplacer();
    this.loading = true;
    await DemandSupplyStore.getVerifyAllocation(query);
    this.loading = false;
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }
    if (this.cropId != null) {
      query["cropTypeId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }

    query["type"] = "demand";

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    query["lang"] = this.$i18n.locale;
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();

    await DemandSupplyStore.getVerifyAllocation(query);

    this.loading = false;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  async clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.searchCode = null;
    // query = this.routerReplacer();
    this.loading = true;
    await DemandSupplyStore.getVerifyAllocation(query);
    this.loading = false;
  }

  demandDetail(id: number) {
    this.$router
      .push({
        name: "DemandDetail",
        params: { clusterId: String(id) }
      })
      .catch(error => {});
  }

  async created() {
    this.language = this.$i18n.locale;

    this.loading = true;
    CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    await MetaStore.getAllFiscalYear();
    await DemandSupplyStore.getVerifyAllocation();
    // await DemandSupplyStore.getAllDemandCluster(query);
    this.loading = false;
  }
}
