var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration fullWidth"},[_c('el-form',[_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('longitude')),
        'is-required': _vm.checkIfRequired(_vm.$t('longitude'))
      },attrs:{"label":_vm.$t('longitude')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate"}],attrs:{"type":"number","name":_vm.$t('longitude')},model:{value:(_vm.user.longitude),callback:function ($$v) {_vm.$set(_vm.user, "longitude", $$v)},expression:"user.longitude"}}),(_vm.vErrors.has(_vm.$t('longitude')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("longitude")))+" ")]):_vm._e()],1),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('latitude')),
        'is-required': _vm.checkIfRequired(_vm.$t('latitude'))
      },attrs:{"label":_vm.$t('latitude')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate"}],attrs:{"type":"number","name":_vm.$t('latitude')},model:{value:(_vm.user.latitude),callback:function ($$v) {_vm.$set(_vm.user, "latitude", $$v)},expression:"user.latitude"}}),(_vm.vErrors.has(_vm.$t('latitude')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("latitude")))+" ")]):_vm._e()],1),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('applicationForSubsidyProgram')),
        'is-required': _vm.checkIfRequired(_vm.$t('applicationForSubsidyProgram'))
      },attrs:{"label":((_vm.$t('applicationForSubsidyProgram')) + " (*.pdf, *.jpg, *.png)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.application,"on-change":_vm.handleAfterUploadApplication,"limit":1,"list-type":"text","action":"","auto-upload":false}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf,image/*'),expression:"'required|mimes:application/pdf,image/*'"}],attrs:{"slot":"trigger","name":_vm.$t('applicationForSubsidyProgram'),"size":"small","type":"text"},slot:"trigger",model:{value:(_vm.application),callback:function ($$v) {_vm.application=$$v},expression:"application"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('applicationForSubsidyProgram')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("applicationForSubsidyProgram")))+" ")]):_vm._e()]),_c('el-form-item',{class:{ 'is-error': _vm.vErrors.has(_vm.$t('PAN')), 'is-required': _vm.checkIfRequired(_vm.$t('PAN')) },attrs:{"label":((_vm.$t('PAN')) + " (*.pdf, *.jpg, *.png)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.pan,"on-change":_vm.handleAfterUploadPAN,"limit":1,"list-type":"text","action":"","auto-upload":false}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf,image/*'),expression:"'required|mimes:application/pdf,image/*'"}],attrs:{"slot":"trigger","name":_vm.$t('PAN'),"size":"small","type":"text"},slot:"trigger",model:{value:(_vm.pan),callback:function ($$v) {_vm.pan=$$v},expression:"pan"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('PAN')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("PAN")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('seedTransactionCertificate')),
        'is-required': _vm.checkIfRequired(_vm.$t('seedTransactionCertificate'))
      },attrs:{"label":((_vm.$t('seedTransactionCertificate')) + " (*.pdf, *.jpg, *.png)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.seed_transaction_certificate,"on-change":_vm.handleAfterUploadSeedTransactionCertificate,"limit":1,"list-type":"text","action":"","auto-upload":false}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf,image/*'),expression:"'required|mimes:application/pdf,image/*'"}],attrs:{"slot":"trigger","name":_vm.$t('seedTransactionCertificate'),"size":"small","type":"text"},slot:"trigger",model:{value:(_vm.seed_transaction_certificate),callback:function ($$v) {_vm.seed_transaction_certificate=$$v},expression:"seed_transaction_certificate"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('seedTransactionCertificate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedTransactionCertificate")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('minuteMeeting')),
        'is-required': _vm.checkIfRequired(_vm.$t('minuteMeeting'))
      },attrs:{"label":((_vm.$t('minuteMeeting')) + " (*.pdf, *.jpg, *.png)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.meeting_minute,"on-change":_vm.handleAfterUploadMeetingMinute,"limit":1,"list-type":"text","action":"","auto-upload":false}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf,image/*'),expression:"'required|mimes:application/pdf,image/*'"}],attrs:{"slot":"trigger","name":_vm.$t('minuteMeeting'),"size":"small","type":"text"},slot:"trigger",model:{value:(_vm.meeting_minute),callback:function ($$v) {_vm.meeting_minute=$$v},expression:"meeting_minute"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('minuteMeeting')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("minuteMeeting")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('firmRegistrationCertificate')),
        'is-required': _vm.checkIfRequired(_vm.$t('firmRegistrationCertificate'))
      },attrs:{"label":((_vm.$t('firmRegistrationCertificate')) + " (*.pdf, *.jpg, *.png)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.firm_registration_certificate,"on-change":_vm.handleAfterUploadFirmRegistrationCertificate,"limit":1,"list-type":"text","action":"","auto-upload":false}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf,image/*'),expression:"'required|mimes:application/pdf,image/*'"}],attrs:{"slot":"trigger","name":_vm.$t('firmRegistrationCertificate'),"size":"small","type":"text"},slot:"trigger",model:{value:(_vm.firm_registration_certificate),callback:function ($$v) {_vm.firm_registration_certificate=$$v},expression:"firm_registration_certificate"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('firmRegistrationCertificate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("firmRegistrationCertificate")))+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }