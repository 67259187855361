


































import Axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UsedDetailDocument extends Vue {
  @Prop({ default: { name: "", mimetype: null, url: "" } })
  document!: any;
  @Prop({ default: false })
  downloadButton!: boolean;
  @Prop({ default: false })
  iconOnly!: boolean;

  forceFileDownload(response: any) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", this.document.name); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  download() {
    Axios({
      method: "get",
      url: this.document.url,
      responseType: "arraybuffer"
    })
      .then((response: any) => {
        this.forceFileDownload(response);
      })
      .catch(() => console.log("error occured"));
  }
}
