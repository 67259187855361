var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"adminMain"},[_c('div',{staticClass:"flex spaceBetween adminMain__head inlineFlex verticleCentering"},[(_vm.roleChecker(['improved_seed_consumer']))?_c('router-link',{attrs:{"to":{ name: 'CompanyMenu' }}},[_c('el-button',{attrs:{"icon":"el-icon-back","circle":""}})],1):_c('router-link',{attrs:{"to":{ name: 'Adminmenu' }}},[_c('el-button',{attrs:{"icon":"el-icon-back","circle":""}})],1),(_vm.roleChecker(['improved_seed_consumer']))?_c('h3',[_vm._v(_vm._s(_vm.$t("verifyBoughtQuantity")))]):_c('h3',[_vm._v(_vm._s(_vm.$t("verifySoldQuantity")))])],1),_c('div',{staticClass:"adminMain__body"},[_c('div',{staticClass:"flex"},[_c('el-form',{attrs:{"inline":"","label-position":"top"}},[_c('el-form-item',{class:{
              'is-error': _vm.vErrors.has(_vm.$t('fiscalYear')),
              'is-required': _vm.checkIfRequired(_vm.$t('fiscalYear')),
              fullWidth: _vm.mobileScreen
            },attrs:{"label":_vm.$t('fiscalYear')}},[_c('el-select',{class:{ fullWidth: _vm.mobileScreen },staticStyle:{"width":"130px"},attrs:{"name":_vm.$t('fiscalYear'),"filterable":""},model:{value:(_vm.fiscalYearId),callback:function ($$v) {_vm.fiscalYearId=$$v},expression:"fiscalYearId"}},[_c('el-option',{attrs:{"label":((_vm.$t('all')) + " " + (_vm.$t('fiscalYear'))),"value":null}}),_vm._l((_vm.FiscalYearList),function(year){return _c('el-option',{key:year.id,attrs:{"value":year.id,"label":_vm.nepaliUnicodeNumber(year.year)}})})],2),(_vm.vErrors.has(_vm.$t('fiscalYear')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("fiscalYear")))+" ")]):_vm._e()],1),_c('el-form-item',{attrs:{"label":_vm.$t('crop')}},[_c('el-select',{staticStyle:{"width":"130px"},attrs:{"placeholder":_vm.$t('select'),"filterable":""},model:{value:(_vm.cropId),callback:function ($$v) {_vm.cropId=$$v},expression:"cropId"}},_vm._l((_vm.crops),function(crop){return _c('el-option',{key:crop.id,attrs:{"value":crop.id,"label":_vm.$i18n.locale == 'ne' && crop.nepname ? crop.nepname : crop.name}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$tc('variety')}},[_c('el-select',{staticStyle:{"width":"160px"},attrs:{"filterable":""},model:{value:(_vm.cropVarietyId),callback:function ($$v) {_vm.cropVarietyId=$$v},expression:"cropVarietyId"}},[_c('el-option',{attrs:{"label":((_vm.$t('all')) + " " + (_vm.$tc('variety', 2))),"value":null}}),_vm._l((_vm.cropVarieties),function(cropVariety){return _c('el-option',{key:cropVariety.id,attrs:{"value":cropVariety.id,"label":_vm.$i18n.locale == 'ne' && cropVariety.nepname
                    ? cropVariety.nepname
                    : cropVariety.name}})})],2)],1),_c('el-form-item',{class:{ fullWidth: _vm.mobileScreen }},[_c('template',{slot:"label"},[_c('br')]),_c('el-button',{class:{ fullWidth: _vm.mobileScreen },attrs:{"type":"success","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],2),_c('el-form-item',[_c('template',{slot:"label"},[_c('br')]),_c('el-button',{class:{ fullWidth: _vm.mobileScreen },attrs:{"icon":"el-icon-refresh"},on:{"click":_vm.clearSearch}},[_vm._v(" "+_vm._s(_vm.$t("clearSearch"))+" ")])],2)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"dataTable",attrs:{"data":_vm.dataList.results}},[_c('el-table-column',{attrs:{"width":"50","label":_vm.$t('S.N.'),"type":"index","index":_vm.indexMethod}}),_c('el-table-column',{attrs:{"width":"100","prop":"fiscal_year","label":_vm.$t('fiscalYear')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.nepaliUnicodeNumber(scope.row.fiscal_year))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"crop","label":_vm.$t('crop')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.$i18n.locale == 'ne' && scope.row.crop.nepname && scope.row.crop.nepname != ''
              )?_c('span',[_vm._v(" "+_vm._s(scope.row.crop.nepname)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.crop.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"crop_variety","label":_vm.$tc('variety')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.$i18n.locale == 'ne' &&
                  scope.row.crop_variety.nepname &&
                  scope.row.crop_variety.nepname != ''
              )?_c('span',[_vm._v(" "+_vm._s(scope.row.crop_variety.nepname)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.crop_variety.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"supplier_company_name","label":_vm.$t('suppliedBy')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.$i18n.locale == 'ne' &&
                  scope.row.supplier_company_nepname &&
                  scope.row.supplier_company_nepname != ''
              )?_c('span',[_vm._v(" "+_vm._s(scope.row.supplier_company_nepname)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.supplier_company_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"demand_company_name","label":_vm.$t('demandBy')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.$i18n.locale == 'ne' &&
                  scope.row.demand_company_nepname &&
                  scope.row.demand_company_nepname != ''
              )?_c('span',[_vm._v(" "+_vm._s(scope.row.demand_company_nepname)+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.demand_company_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"total_allocated_quantity","label":((_vm.$t('allocated')) + " (" + (_vm.$t('kg')) + ")")},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.nepaliUnicodeNumber(_vm.thousandSeparator(scope.row.total_allocated_quantity)))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sold_quantity","label":((_vm.roleChecker(['improved_seed_consumer']) ? _vm.$t('boughtQuantity') : _vm.$t('soldQuantity')) + " (" + (_vm.$t('kg')) + ")")},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.nepaliUnicodeNumber(_vm.thousandSeparator(scope.row.sold_quantity)))+" ")]}}])}),_c('el-table-column',{attrs:{"width":"150","fixed":"right","label":_vm.roleChecker(['improved_seed_consumer'])
              ? _vm.$t('boughtActualBill')
              : _vm.$t('salesActualBill')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.document_submitted == true)?_c('span',[_c('el-button',{staticStyle:{"color":"#67C23A"},attrs:{"type":"text","icon":"el-icon-circle-check","title":"Valid"}})],1):(scope.row.document_submitted == false)?_c('span',[_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-circle-close","title":"Invalid"}})],1):_c('span',[_c('el-button',{attrs:{"type":"text","icon":"el-icon-question","title":"Verify Document"},on:{"click":function($event){return _vm.toggleDialog(scope.row)}}})],1)]}}])})],1),_c('div',{staticClass:"text-right adminMain__footer"},[_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-count":_vm.dataList.total_pages,"background":"","layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event},"current-change":_vm.handlePageChange}})],1),_c('el-dialog',{attrs:{"title":_vm.roleChecker(['improved_seed_consumer'])
            ? _vm.$t('isBoughtDocumentValid')
            : _vm.$t('isDocumentValid'),"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('div',{staticStyle:{"padding-bottom":"20px"}},[_c('el-row',[_c('el-col',{attrs:{"sm":12}},[_c('strong',[_vm._v(_vm._s(_vm.$t("allocated"))+" ("+_vm._s(_vm.$t("kg"))+"):")]),_vm._v(" "+_vm._s(_vm.myAllocation.total_allocated_quantity)+" ")]),_c('el-col',{attrs:{"sm":12}},[_c('strong',[_vm._v(_vm._s(_vm.roleChecker(["improved_seed_consumer"]) ? _vm.$t("boughtQuantity") : _vm.$t("soldQuantity"))+" ("+_vm._s(_vm.$t("kg"))+"):")]),_vm._v(" "+_vm._s(_vm.myAllocation.sold_quantity)+" ")])],1)],1),_c('h6',[_vm._v(" "+_vm._s(_vm.roleChecker(["improved_seed_consumer"]) ? _vm.$t("boughtBill") : _vm.$t("salesBill"))+" ")]),_c('UserDetailDocument',{attrs:{"iconOnly":false,"downloadButton":true,"document":{
            url: _vm.myAllocation.seed_sale_bill,
            name: ((_vm.roleChecker(['improved_seed_consumer']) ? _vm.$t('boughtBill') : _vm.$t('salesBill')) + ".pdf")
          }}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-close"},on:{"click":function($event){return _vm.patchSalesBillValidity(false)}}},[_vm._v(_vm._s(_vm.$t("invalid")))]),_c('el-button',{attrs:{"type":"success","icon":"el-icon-check"},on:{"click":function($event){return _vm.patchSalesBillValidity(true)}}},[_vm._v(_vm._s(_vm.$t("valid")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogFormVisible = false}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }