















































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Validator, Rule } from "vee-validate";

Validator.extend("fileUploaded", {
  getMessage: (field: any) => {
    return "At least one image is required";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

Validator.extend("fileType", {
  getMessage: (field: any) => {
    return "The file must be an jpeg or png image.";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        let imgType = args[0][0].type;
        if (imgType === "image/jpeg" || imgType === "image/png") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

@Component({
  inject: ["$validator"]
})
export default class Registrationthree extends Vue {
  @Prop({ default: {} })
  user!: any;

  application: File[] = [] as File[];
  pan: File[] = [] as File[];
  seed_transaction_certificate: File[] = [] as File[];
  meeting_minute: File[] = [] as File[];
  firm_registration_certificate: File[] = [] as File[];

  date: any = "";
  pickdate = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
    shortcuts: [
      {
        text: "Today",
        onClick(picker: any) {
          picker.$emit("pick", new Date());
        }
      },
      {
        text: "Yesterday",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", date);
        }
      },
      {
        text: "A week ago",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", date);
        }
      }
    ]
  };

  handleAfterUploadApplication(file: any, fileList: any[]) {
    this.application = [];
    this.application.push(file.raw);
    this.$emit("uploadApplication", file.raw);
  }

  handleAfterUploadPAN(file: any, fileList: any[]) {
    this.pan = [];
    this.pan.push(file.raw);
    this.$emit("uploadPan", file.raw);
  }

  handleAfterUploadSeedTransactionCertificate(file: any, fileList: any[]) {
    this.seed_transaction_certificate = [];
    this.seed_transaction_certificate.push(file.raw);
    this.$emit("uploadSeedTransactionCertificate", file.raw);
  }

  handleAfterUploadMeetingMinute(file: any, fileList: any[]) {
    this.meeting_minute = [];
    this.meeting_minute.push(file.raw);
    this.$emit("uploadMeetingMinute", file.raw);
  }

  handleAfterUploadFirmRegistrationCertificate(file: any, fileList: any[]) {
    this.firm_registration_certificate = [];
    this.firm_registration_certificate.push(file.raw);
    this.$emit("uploadFirmRegistrationCertificate", file.raw);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
